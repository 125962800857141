@font-face {
    font-family: "Microgramma D Extended";
    src: url("../public/assets/microgramma-d-extended-medium.ttf");
    format: ("ttf");
    font-display: swap;
}  

/* Override Default gutterBottom prop */
@media only screen and (max-width: 600px) {
    .MuiTypography-gutterBottom	{
        margin-bottom: 16px !important;
    }
}

@media only screen and (min-width: 601px) {
    .MuiTypography-gutterBottom	{
        margin-bottom: 32px !important;
    }
}

.hero {
    max-width: 1500;
    margin: 0 auto;
}
